import cssLogo from "./logos/css.png";
import gitLogo from "./logos/git.png";
import githubLogo from "./logos/github.png";
import htmlLogo from "./logos/html.png";
import javascriptLogo from "./logos/javascript.png";
import typescriptLogo from "./logos/typescript.png";
import reactNativeLogo from "./logos/react-native.png";
import knockoutLogo from "./logos/knockout.png";
import netlifyLogo from "./logos/netlify.png";
import npmLogo from "./logos/npm.png";
import sassLogo from "./logos/sass.png";

export const TechLogos = [
  {
    name: "HTML",
    logo: htmlLogo,
    url: "https://developer.mozilla.org/en-US/docs/Web/HTML",
  },
  {
    name: "CSS",
    logo: cssLogo,
    url: "https://developer.mozilla.org/en-US/docs/Web/CSS",
  },
  { name: "Sass", logo: sassLogo, url: "https://sass-lang.com/" },
  {
    name: "JavaScript",
    logo: javascriptLogo,
    url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
  },
  {
    name: "React Native",
    logo: reactNativeLogo,
    url: "https://reactjs.org/",
  },
  {
    name: "TypeScript",
    logo: typescriptLogo,
    url: "https://www.typescriptlang.org/",
  },
  { name: "Knockout", logo: knockoutLogo, url: "https://knockoutjs.com/" },
  { name: "Git", logo: gitLogo, url: "https://git-scm.com/" },
  { name: "GitHub", logo: githubLogo, url: "https://github.com/" },
  { name: "Netlify", logo: netlifyLogo, url: "https://www.netlify.com/" },
  { name: "npm", logo: npmLogo, url: "https://www.npmjs.com/" },
];