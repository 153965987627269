import { TechLogos } from "../assets/Database.js";

import "./Logos.css";

const Logos = () => {
  return (
    <div className="logo-container">
      {TechLogos.map((logo, index) => (
        <span key={logo.name} title={logo.name}>
          <a href={logo.url}>
            <img className="logo" key={index} src={logo.logo} alt={logo.name} />
          </a>
        </span>
      ))}
    </div>
  );
};

export default Logos;
