import "./App.css";
import jamie from "./assets/images/jamieB&W.png";
import Logos from "./Components/Logos";

function App() {
  return (
    <>
      <div className="site-container">
        <div className="info">
          <div className="name">Jamie Kielland</div>
          <div className="job">Web Developer</div>
          <div className="email">
            Email:&nbsp;
            <a type="email" href="mailto:jamiekielland@gmail.com">
              Jamiekielland@gmail.com
            </a>
          </div>
        </div>
        <div className="image">
          <img alt="portrait" height="200px" src={jamie} />
        </div>
      </div>
      <Logos />
    </>
  );
}

export default App;
